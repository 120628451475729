import Echo from 'laravel-echo';

import Pusher from 'pusher-js';
window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'reverb',
    key: import.meta.env.VITE_REVERB_APP_KEY,
    wsHost: import.meta.env.VITE_REVERB_HOST,
    wsPort: import.meta.env.VITE_REVERB_PORT ?? 80,
    wssPort: import.meta.env.VITE_REVERB_PORT ?? 443,
    forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
});

LocalUser.onSetup(() => {

    console.log("[REVERB] LocalUser setup, listening on user channel");

    window.Echo.private(`App.Models.User.${LocalUser.ID}`).notification((notif) => {

        LambdaPushNotifications.onNewNotification(notif);
    
    });

});

